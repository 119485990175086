body:not(.is-mobile) {
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        background-color: rgba(0, 0, 0, 0.12);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        -webkit-box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
        box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
        border-radius: 24px;
    }

    ::-webkit-scrollbar-thumb:active {
        -webkit-box-shadow: inset 0 0 0 24px rgba(0, 0, 0, 0.54);
        box-shadow: inset 0 0 0 24px rgba(0, 0, 0, 0.54);
        border-radius: 24px;
    }
    .nav-link {
        height: 40px;
        padding: 0 12px 0 24px;
        border-radius: 0 25px 25px 0;
        margin-right: 16px;
    }
}
