button,
input[type="email"],
input[type="tel"],
input[type="text"],
input[type="password"],
input[type="image"],
input[type="submit"],
input[type="button"],
input[type="search"],
textarea {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
}
