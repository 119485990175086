// Import Fuse core library
@use '@angular/material' as mat;
//@import '@angular/cdk/overlay-prebuilt.css';

@import "@fuse/scss/core";
// -----------------------------------------------------------------------------------------------------
// @ Multiple material themes example
// -----------------------------------------------------------------------------------------------------
@import "node_modules/angular-calendar/scss/angular-calendar";
@import url("https://fonts.googleapis.com/css?family=Tajawal&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@600;700&display=swap');

body{
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
  }

.default-dialog {
    mat-dialog-container {
        padding: 24px !important;
    }
}

.cairo-text{
    font-family: Cairo;
    font-style: normal;
}

.cdk-global-scrollblock {
    overflow-y: auto !important;
}

// Define the Material palettes
$yellow-theme-primary: mat.define-palette($mat-fusedark);
$yellow-theme-accent: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
$yellow-theme-warn: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$yellow-theme: mat.define-light-theme(
    $yellow-theme-primary,
    $yellow-theme-accent,
    $yellow-theme-warn
);

// Add ".yellow-theme" class to the body to activate this theme
.yellow-theme {
    // Generate the Angular Material theme
    @include mat.all-component-themes($yellow-theme);

    // Generate the fuse color classes with the new colors...
    @include generateFuseColorClasses(
        $yellow-theme-primary,
        $yellow-theme-accent,
        $yellow-theme-warn
    );
}

.bg-white {
    background-color: white;
    margin: 1px;
}

.cdk-global-overlay-wrapper{
    @include media-breakpoint("xs") {
        align-items: flex-end !important;
    }
}

.common-dialog-style{
    @include media-breakpoint("xs") {
        width: 100% !important;
        max-width: 100vw !important;
        height: 90vh !important;
        max-height: 90vh !important;
        .mat-dialog-actions.buttons {
            justify-content: center;
        }
    }

    @include media-breakpoint-up("xs") {
        width: auto;
    }

    .mat-dialog-container {
        padding: 0 !important;
        border-radius: 15px;
        height: fit-content;
        
        @include media-breakpoint("xs") {
            align-self: flex-end !important;
                    border-radius: 0;
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    -ms-border-radius: 0;
                    -o-border-radius: 0;
    }
    }

    .dialog-content-wrapper {
        padding: 0% !important;
        margin: 0%;
        max-height: 90vh;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .primary-color-input,
    .secondary-color-input {
        padding: 6px 8px;
    }
}

.common-dialog-large-style{
    @include media-breakpoint("xs") {
        width: 100% !important;
        max-width: 100vw !important;
        height: 100vh !important;
        max-height: 100vh !important;
    }

    @include media-breakpoint-up("xs") {
        width: auto;
    }
    width: min(100vw,800px);



}


/* .my-custom-class>span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    //align-items: center;
    padding: 14px 7px 0px 7px;
    height: 3em;
    background: white !important;
    font-size: 14px;
    color: black;
    width: 80%;
    margin: 16px 16px 8px 16px;
}

.cal-event-title {
    color: black;
    width: 80%;
    background: white;
}

#mwl-calendar-event-title {
    color: red !important;
    background-color: purple;
} */
.cal-week-view .cal-time-events {
    max-height: calc(100vh - 330px);
    overflow-y: auto;

    @media screen and (max-width:1000px) {
        max-height: calc(100vh - 280px);
      }
  }

 

  .cal-day-headers {
    margin-right: 14.5px;
  }

  .cal-day-columns {
    height: 100%;
    
  }



mwl-calendar-month-cell,
mwl-calendar-day-view-hour-segment,
mwl-calendar-week-view-hour-segment {
    -ms-touch-action: auto !important;
    touch-action: auto !important;
}
.cal-event {
    -ms-touch-action: auto !important;
    touch-action: auto !important;
}
//.disabled-dark
.mat-select-disabled .mat-select-value {
    color: #039be5; // rgba(34, 15, 141, 0.74);
}

.material-icons.green {
    color: green;
}
.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}
.material-icons.red {
    color: red;
}

.cal-event-title {
    white-space: pre-wrap;
}

.cal-day-view .cal-event {
    color: white;
    font-weight: 500;
}

.cal-week-view .cal-event {
    color: white;
    font-weight: 500;
}

.cal-month-view {
    .cal-header {
        .cal-cell {
            font-weight: 500;
        }
    }

    .cal-day-cell {
        height: 100;
        width: 170;

        @include media-breakpoint(lg) {
            min-height: 100px;
        }

        @include media-breakpoint(gt-lg) {
            min-height: 100px;
        }

        &.cal-open {
            @include mat.elevation(3);
        }
    }

    .cal-open-day-events {
        background: rgb(51, 110, 221);
        -webkit-box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.54);
        box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.54);
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        > div {
            padding: 0 16px;
            margin: 8px 16px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            background-color: white;
            @include mat.elevation(1);
            -webkit-transition: -webkit-box-shadow 300ms ease;
            transition: -webkit-box-shadow 300ms ease;
            -o-transition: box-shadow 300ms ease;
            transition: box-shadow 300ms ease;
            transition: box-shadow 300ms ease, -webkit-box-shadow 300ms ease;

            &:first-of-type {
                margin-top: 16px;
            }

            &:last-of-type {
                margin-bottom: 16px;
            }

            &:hover {
                @include mat.elevation(3);
            }

            .cal-event {
                top: 0;
                margin: 0;
            }

            mwl-calendar-event-title {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;

                .cal-event-title {
                    display: block;
                    padding: 21px 24px;
                    line-height: 1;
                    text-decoration: none;
                    color: black;
                }
            }

            mwl-calendar-event-actions {
                .cal-event-actions {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;

                    .cal-event-action {
                        display: block;
                        line-height: 1;
                        padding: 8px;
                    }
                }
            }
        }
    }
}

.mat-cell {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.fail {
    background-color: #f44336 !important;
    color: #fff !important;
    border: 50%;
    border-radius: 50%;
}
.mat-row:hover {
    cursor: pointer;
    background-color: #ececee !important;
}

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      height: 100vw;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
  } */

.cdk-global-overlay-wrapper:has(.borderless-dialog-style) {
    align-items: center!important;
}